<template>
    <div>
        <div class="card card-default" :class="loading ? 'whirl' : ''">
            <div class="card-header bg-gray-lighter text-bold">{{$t('common.view.settings.account.heading')}}</div>
            <div class="card-body">
                <b-btn variant="danger" @click="closeAccount">{{ $t('common.view.settings.account.delete.button') }}</b-btn>
              <small v-if="$store.getters.user('is_sponsor_movable')"><br/><b-btn variant="link" class="p-0 m-0 align-baseline" :to="{name: 'SettingsSponsor'}">{{ $t('common.view.settings.account.button.change_sponsor.button.label') }}</b-btn></small>
            </div>
        </div>

        <div class="card card-default" :class="loading ? 'whirl' : ''">
            <div class="card-header bg-gray-lighter text-bold">{{ $t('common.view.settings.account.password.heading') }}</div>
            <div class="card-body">

                <form @submit.prevent="updatePassword()">
                    <div class="form-group">
                        <label>{{$t('common.view.settings.account.password.current')}}</label>
                        <input class="form-control" type="password" v-model="user.password_update.password"/>
                    </div>

                    <b-row>
                        <b-col sm="6">
                            <div class="form-group">
                                <label>{{$t('common.view.settings.account.password.new')}}</label>
                                <input class="form-control" type="password" v-model="user.password_update.new_password"/>
                            </div>
                        </b-col>
                        <b-col sm="6">
                            <div class="form-group">
                                <label>{{$t('common.view.settings.account.password.confirm')}}</label>
                                <input class="form-control" type="password" v-model="user.password_update.new_password_confirmation"/>
                            </div>
                        </b-col>
                    </b-row>

                    <button class="btn btn-info" type="submit" :disabled="loading">{{$t('common.view.settings.account.password.update')}}</button>
                </form>
            </div>
        </div>

        <div class="card card-default" :class="loading ? 'whirl' : ''">
            <div class="card-header bg-gray-lighter text-bold">{{$t('common.view.settings.email.heading')}}</div>
            <div class="card-body">
                <p>{{$t('common.view.settings.email.description')}}</p>

                <p class="alert alert-warning" v-if="!$store.getters.user('email_verified')">{{$t('common.view.settings.email.unverified.label')}}</p>

                <p>
                    <strong>{{$t('common.view.settings.email.current')}}</strong>
                </p>
                <p>
                    <span class="mr-2">{{ $store.getters.user('email') }}</span>
                    <span class="badge badge-info">{{$t('common.view.settings.email.primary')}}</span>
                    <span class="badge badge-info" v-if="$store.getters.user('email_verified')">{{$t('common.view.settings.email.verified')}}</span>
                    <span class="badge badge-warning" v-if="!$store.getters.user('email_verified')">{{$t('common.view.settings.email.not_verified')}}</span>
                </p>
            </div>
            <div class="card-body bt">
                <p>
                    <strong>{{$t('common.view.settings.email.button.update')}}</strong>
                </p>
                <form @submit.prevent="updateEmail()">
                    <div class="row mb-2">
                        <div class="col-xl-6">
                            <div class="form-group">
                                <div class="input-group">
                                    <input class="form-control" type="email" v-model="user.email" placeholder="email@server.com"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-info" type="submit">{{$t('common.view.settings.email.button.update')}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        components: {},
        data() {
            return {
                user: {
                    password_update: {},
                    email: '',
                },
                loading: false,
            }
        },
        methods: {
            updatePassword() {
                this.$auth.requestVerfificationCode().then(code => {
                    this.$api.update('auth/password', {
                        verification_code: code.verification_code,
                        password: this.user.password_update.password,
                        new_password: this.user.password_update.new_password,
                        new_password_confirmation: this.user.password_update.new_password_confirmation
                    })
                        .then((response) => {
                            this.$swal.fire('Password changed!', '', 'success');
                        })
                        .catch((error) => {
                            if (error.status === 422) {
                                this.$swal.fire('Error', error.data.message, 'error');
                            }
                        });
                });
            },
            updateEmail() {
                this.loading = true;
                this.$auth.requestVerfificationCode().then(code => {
                    this.$api.update('auth/email', {email: this.user.email, verification_code: code.verification_code}).then(response => {
                        this.$store.commit('updateUser', response.data);
                        this.$swal.fire('Email changed', '', 'success');
                        this.loading = false;
                    }).catch(error => {
                        this.$auth.showErrorMessage(error);
                        this.loading = false;
                    });
                });
            },
            closeAccount() {
                this.$swal.fire({
                    title: 'Delete Account',
                    icon: 'danger',
                    confirmButtonText: 'Yes, I\'m sure',
                    showCancelButton: true
                }).then(response => {
                    if (response.value) {
                        this.$auth.requestVerfificationCode().then(code => {
                            this.$api.post('user/close', {verification_code: code.verification_code}).then(response => {
                                this.$auth.logout();
                            });
                        });
                    }
                })
            }
        }
    }
</script>